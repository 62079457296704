import React from 'react'
import { HashLink } from 'react-router-hash-link';
import './styles.scss'

export default function About() {
  return (
    <div className='about-wrapper'>
        <div className='about-container'>
            <h3>About Us</h3>
            <p>High quality proxies and gmails to take your cooks to the next level. ne0proxies was created because I got tired of getting the run around from random providers who were offering sub-par products. All of our products are sourced from high quality providers, and I'll be running them along side you, and will provide you with tips and tricks so that we can cook together. No BS and full transparency is our mission.</p>
            <button ><HashLink smooth to="/#features" className='a2'>Learn More</HashLink></button>
        </div>
    </div>
  )
}
