import React from 'react';
import Footer from '../../components/Footer/Footer';
import About from '../About/About';
import FAQ from '../FAQ/FAQ';
import Features from '../Features/Features';
import Home from '../Home/Home'
import Plans from '../Plans/Plans';


export default function Main() {
  return <div>
      <Home />
      <About />
      <Features />
      <Plans />
      <FAQ />
      <Footer />
  </div>;
}
