import {
  Routes,
  Route,
} from "react-router-dom";
import MobileNavbar from './components/MobileNavbar/MobileNavbar';
import Navbar from './components/Navbar/Navbar'
import Main from './pages/Main/Main';
import PP from "./pages/PP/PP";
import TOS from './pages/TOS/TOS';

function App() {
  return (
    <div className="app">
          <Navbar />
          <MobileNavbar />
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/terms-of-services" element={<TOS/>}/>
            <Route path="/privacy-policy" element={<PP/>}/>
          </Routes>
    </div>
  );
}

export default App;
