import React, {useEffect} from 'react';
import AOS from "aos";
import logo from '../../images/logo.png'
import './styles.scss'
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';


export default function Footer() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <div className='footer-wrapper'>
      <div className='footer-content' >

        <div>
          <img src={logo} alt="" />
          <p>©2022 neOproxies</p>
        </div>

        <div>
          <h6>Links</h6>
          <Link to="/privacy-policy">Privacy Policy</Link>
          <Link to='/terms-of-services'>Terms of Service</Link>
        </div>

        <div>
          <h6>Website</h6>
          <HashLink smooth to="/#home">Home</HashLink>
          <HashLink smooth to="/#features">Features</HashLink>
          <HashLink smooth to="/#pricing">Pricing</HashLink>
          <HashLink smooth to="/#faq">FAQ</HashLink>
        </div>

        <div>
          <h6>Socials</h6>
          <a href="">Twitter</a>
          <a href="">Discord</a>
        </div>

      </div>
    </div>
  )
}
