import React, {useEffect} from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import './styles.scss'

export default function PlanCard(props) {
    const {data} = props;
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
 
  return (
    <div className='planCard-wrapper' data-aos="flip-down">
            <div className='title-container'>
              <h5>{data.location}</h5>
              <h3>{data.title}</h3>
            </div>
                     
           <div>
                <p>{data.l1}</p>
                <p>{data.l2}</p>
                <p>{data.l3}</p>
                {data.l4 && <p>{data.l4}</p>}
                {data.l5 && <p>{data.l5}</p>}
           </div>
                
            <select>
                <option>{data.opt1}</option>
                {data.opt2 && <option>{data.opt2}</option>}
                {data.opt3 && <option>{data.opt3}</option>}
                {data.opt4 && <option>{data.opt4}</option>}
                {data.opt5 && <option>{data.opt5}</option>}
                {data.opt6 && <option>{data.opt6}</option>}
            </select>

           
            <a href="https://dashboard.ne0proxies.com"><button>Buy Now</button></a>
    </div>
  )
}
