import React from 'react'
import "aos/dist/aos.css";
import light from '../../images/light.png'
import support from '../../images/support.png'
import globe from '../../images/globe.png'
import './styles.scss'
import FeaturesCard from './FeaturesCard/FeaturesCard';
import { Link } from 'react-router-dom';

export default function Features() {

  return (
    <div className='features-wrapper' id='features' >
      <div className='features-container'>
         
        <div className='left'>
          <h4>FEATURES</h4>
          <h2>What We Have To Offer.</h2>
          <p>We have a full range of different products to support your sneaker botting career from ISPs and residential proxies.</p>
          <button > <a href="https://dashboard.ne0proxies.com" >Buy Now</a></button>
        </div>
       
        <div className='right'>
          {[
            {
              icon: light,
              title: "Virgin ISPs",
              para: "Working on all target sites. Use them to farm Gmails and run tasks. Unthrottled on Shopify and Guaranteed to work on Footsites and YeezySupply."
           
            },
            {
              icon: support,
              title: "Helpful Support",
              para: "Our staff knows how to bot and can resolve any issues you encounter. Tickets are given attention quickly."
          
            },
            {
              icon: globe,
              title: "Residential Proxies",
              para: "From the largest providers in the game. We have all of your bases covered!"
          
            },
           
          ].map((item, i) => (
            <FeaturesCard key={i} data={item}/>
          ))}
        </div>
      </div>
    </div>
  )
}
