import React, {useEffect} from 'react';
import AOS from "aos";
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import logo from '../../images/logo2.png'
import './styles.scss'

export default function Home() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return  <div className='home-wrapper' id="home" data-aos="zoom-in">
      <div className='home-container'>
        <div className='left'>
          <h1>neOproxies</h1>
          <p>Let's Cook Together</p>
          <div className='button-container'>
            <button className='btn1'>   <a href="https://dashboard.ne0proxies.com" >Buy Now</a></button>
            <button className='btn2'><HashLink smooth to="/#features" className='a2'>Learn More</HashLink></button>
          </div>
        </div>
         
         <div className='right'>
           <img src={logo} alt="" />
         </div>
      </div>
  </div>;
}
