import React, {useState} from 'react'
import PlanCard from './PlanCard/PlanCard'
import './styles.scss'

export default function Plans() {
  const [clicked, setClicked] = useState('isp')
  return (
    <div className='plans-wrapper' id="pricing">
      <div className='plans-container'>
        <h4>PRICING</h4>
        <h2>Proxies</h2>
        <div className='tabs-container'>
          <button className={clicked === 'isp' ? 'selected' : ""} onClick={()=>setClicked('isp')}>ISPs</button>
          <button onClick={()=>setClicked('gmails')} className={clicked === 'gmails' ? 'selected' : ""}>Gmails</button>
          <button onClick={()=>setClicked('residential')} className={clicked === 'residential' ? 'selected' : ""}>Residential</button>
        </div>
        {clicked === 'gmails'  && <div className='content'>
          {[
             {
              title: "Aged Gmails",
              l1: "Ready for you to put on a proxy and farm",
             
              opt1: "1 - $4",
              opt2: "2 - $8",
              opt3: "3 - $12",
              opt4: "5 - $20",
              opt5: "10 - $40",
              
            },
            {
              title: "Premium",
              l1: "2014 OC v2/0.9 v3 Gmails Ready to plug and run for drops ISP Proxy included with each Gmail, guaranteed to be working for the drop you purchase them for.",
             
              opt1: "1 - $10",
              opt2: "2 - $20",
              opt3: "3 - $30",
              opt4: "5 - $50",
              opt5: "10 - $100",
              
            },
          
       
          ].map((item, i)=> (
            <PlanCard key={i} data={item}/>
          ))}
        </div>}

        {clicked === 'isp'  && <div className='content'>
          {[
            {
              title: "Virgin ISPs",
              l1: "Unbanned on most sites",
              l2: "99% uptime",
              l3: "30 days expiry date",
              l4: "Unlimited data (no restrictions)",
              l5: "User:Pass authenticated",

              opt1: "25 ISP - $50",
              opt2: "50 ISP - $100",
              opt3: "100 ISP - $200",
              opt4: "200 ISP - $400",          
              
            },
            
          ].map((item, i)=> (
            <PlanCard key={i} data={item}/>
          ))}
        </div>}

        {clicked === 'residential'  && <div className='content'>
          {[
            {
              title: "Residentials",
              l1: "From the largest providers in the game.",
              l2: "Data never expires",
              opt1: "5GB - $65",
              opt2: "10GB - $130",
              opt3: "15GB - $195",
              opt4: "20GB - $260",  
              opt5: "25 GB - $325"        
              
            },
        
            
          ].map((item, i)=> (
            <PlanCard key={i} data={item}/>
          ))}
        </div>}
      
      </div>
    </div>
  )
}
